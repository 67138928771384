import React, { useState, useEffect, useRef } from "react";
import { X, Sun, Moon, RefreshCw, Menu } from "lucide-react";
import ResistorDots from "./components/ResistorDots";
import { colorCodes, multipliers } from "./constants/colorCodes";
import StructuredData from "./components/StructuredData";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [value, setValue] = useState("");
  const [selectedMultiplier, setSelectedMultiplier] = useState("none");
  const [quantity, setQuantity] = useState("1");
  const [resistors, setResistors] = useState(() => {
    const savedResistors = localStorage.getItem("resistors");
    return savedResistors ? JSON.parse(savedResistors) : [];
  });
  const [selectedBands, setSelectedBands] = useState({ 4: true });
  const [darkMode, setDarkMode] = useState(false);
  const valueInputRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("resistors", JSON.stringify(resistors));
  }, [resistors]);

  useEffect(() => {
    const isDark = localStorage.getItem("darkMode") === "true";
    setDarkMode(isDark);
    if (isDark) {
      document.documentElement.classList.add("dark");
    }
  }, []);

  useEffect(() => {
    const savedResistors = localStorage.getItem("savedResistors");
    if (savedResistors) {
      setResistors(JSON.parse(savedResistors));
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle("dark");
    localStorage.setItem("darkMode", (!darkMode).toString());
  };

  const handleReset = () => {
    if (window.confirm("Are you sure you want to clear all resistors?")) {
      localStorage.removeItem("resistors");
      setResistors([]);
    }
  };

  const handleValueChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);

    const match = inputValue.match(/^(\d*\.?\d*)([KkMm])?$/);
    if (match) {
      const [, numberPart, suffix] = match;
      if (suffix) {
        const upperSuffix = suffix.toUpperCase();
        setSelectedMultiplier(upperSuffix);
      }
    }
  };

  const handleBandToggle = (bandNumber) => {
    setSelectedBands((prev) => {
      const newBands = { ...prev };
      if (newBands[bandNumber]) {
        delete newBands[bandNumber];
      } else {
        newBands[bandNumber] = true;
      }
      return newBands;
    });
  };

  const getColorBands = (valueStr, multiplierKey, numBands) => {
    // Remove any K or M suffix
    const cleanValue = valueStr.replace(/[KkMm]/, "");

    // Convert value to a number and handle K/M multipliers
    let numericValue = parseFloat(cleanValue);
    if (multiplierKey === "K") {
      numericValue *= 1000;
    } else if (multiplierKey === "M") {
      numericValue *= 1000000;
    }

    // Convert to string and pad with zeros if needed
    let valueString = numericValue.toString().replace(".", "");

    let significantDigits;
    let multiplierPower = 0;

    // Calculate the multiplier (number of zeros needed)
    if (numBands === 3 || numBands === 4) {
      significantDigits = 2;
    } else {
      significantDigits = 3;
    }

    // Pad with zeros if needed
    while (valueString.length < significantDigits) {
      valueString = valueString + "0";
      multiplierPower--;
    }

    // Calculate how many zeros to remove/add for the multiplier
    multiplierPower += valueString.length - significantDigits;

    // Get the significant digits
    const digits = valueString
      .slice(0, significantDigits)
      .split("")
      .map(Number);

    return {
      bands: digits,
      multiplierBand: multiplierPower,
      numBands,
    };
  };

  const handleAdd = () => {
    if (!value || Object.keys(selectedBands).length === 0) return;

    const multiplier = multipliers[selectedMultiplier];

    const newResistors = Object.keys(selectedBands).map((numBands) => {
      const colors = getColorBands(
        value,
        selectedMultiplier,
        parseInt(numBands)
      );
      return {
        value: parseFloat(value.replace(/[KkMm]/, "")) * multiplier.value,
        quantity: parseInt(quantity),
        bands: colors.bands,
        multiplierBand: colors.multiplierBand,
        numBands: parseInt(numBands),
      };
    });

    setResistors([...resistors, ...newResistors]);
    setValue("");
    setQuantity("1");
    setSelectedMultiplier("none");

    if (valueInputRef.current) {
      valueInputRef.current.focus();
      valueInputRef.current.select();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent form submission if within a form
      handleAdd();
    }
  };
  const handleDelete = (index) => {
    setResistors((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/*vercel analytics*/}

      <Analytics />

      <div
        className={`flex-grow ${
          darkMode
            ? "dark bg-gray-900 text-stone-100"
            : "bg-stone-50 text-stone-900"
        }`}
      >
        {/* Desktop buttons - fixed top right */}
        <div className="fixed right-4 top-4 hidden md:flex gap-2 print:hidden">
          {resistors.length > 0 && (
            <button
              onClick={handleReset}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 text-red-500 dark:text-red-400"
              title="Clear all resistors"
            >
              <RefreshCw className="w-6 h-6" />
            </button>
          )}
          <button
            onClick={toggleDarkMode}
            className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            {darkMode ? (
              <Sun className="w-6 h-6" />
            ) : (
              <Moon className="w-6 h-6" />
            )}
          </button>
        </div>

        <header className="md:hidden fixed top-0 left-0 right-0 h-16 bg-stone-100 dark:bg-gray-800 shadow-sm z-50 flex items-center justify-between px-4 print:hidden">
          <img
            src="/logo192.png"
            alt="Resistor Organizer"
            className="h-8 w-auto"
          />

          <div className="relative">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Menu className="w-6 h-6" />
            </button>

            {isMenuOpen && (
              <>
                <div
                  className="fixed inset-0 bg-transparent"
                  onClick={() => setIsMenuOpen(false)}
                />
                <div className="absolute right-0 mt-2 w-48 bg-amber-100 dark:bg-gray-800 rounded-lg shadow-lg border dark:border-gray-700">
                  <div className="p-2 space-y-2">
                    {resistors.length > 0 && (
                      <button
                        onClick={() => {
                          handleReset();
                          setIsMenuOpen(false);
                        }}
                        className="w-full flex items-center gap-2 px-4 py-2 text-red-500 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                      >
                        <RefreshCw className="w-5 h-5" />
                        <span>Clear List</span>
                      </button>
                    )}
                    <button
                      onClick={() => {
                        toggleDarkMode();
                        setIsMenuOpen(false);
                      }}
                      className="w-full flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                    >
                      {darkMode ? (
                        <>
                          <Sun className="w-5 h-5" />
                          <span>Light Mode</span>
                        </>
                      ) : (
                        <>
                          <Moon className="w-5 h-5" />
                          <span>Dark Mode</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </header>
        <div className="md:hidden h-16" />

        <div className="max-w-2xl mx-auto p-4 relative">
          {/* Controls section - centered */}
          <div className="print:hidden flex flex-col items-center justify-center mt-8">
            <div className="w-full max-w-md mb-4 flex items-center justify-center gap-4">
              <span className="text-gray-700 dark:text-gray-300">
                Number of Bands:
              </span>
              <div className="flex gap-3">
                {[3, 4, 5, 6].map((num) => (
                  <div key={num} className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      id={`band-${num}`}
                      checked={selectedBands[num] || false}
                      onChange={() => handleBandToggle(num)}
                      className="w-4 h-4"
                    />
                    <label
                      htmlFor={`band-${num}`}
                      className="text-gray-700 dark:text-gray-300"
                    >
                      {num}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full max-w-md flex gap-2 mb-6">
              <input
                ref={valueInputRef}
                value={value}
                onChange={handleValueChange}
                onKeyDown={handleKeyDown}
                placeholder="Value"
                className="w-32 px-3 py-2 border rounded dark:bg-gray-800 dark:border-gray-700"
              />
              <select
                value={selectedMultiplier}
                onChange={(e) => setSelectedMultiplier(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-24 px-3 py-2 border rounded dark:bg-gray-800 dark:border-gray-700"
              >
                <option value="none">Ω</option>
                <option value="K">KΩ</option>
                <option value="M">MΩ</option>
              </select>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Quantity"
                className="w-24 px-3 py-2 border rounded dark:bg-gray-800 dark:border-gray-700"
              />
              <button
                onClick={handleAdd}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Add
              </button>
            </div>
          </div>

          {/* Resistors list */}
          <div className="space-y-4">
            {resistors.map((resistor, index) => (
              <div
                key={index}
                className="p-4 relative border rounded-lg shadow bg-stone-100 dark:bg-gray-800 dark:border-gray-700 print:bg-white print:border-gray-200"
              >
                <button
                  className="absolute right-2 top-2 h-8 w-8 flex items-center justify-center hover:bg-red-100 hover:text-red-600 rounded print:hidden"
                  onClick={() => handleDelete(index)}
                >
                  <X className="h-4 w-4" />
                </button>
                <div className="p-0">
                  <div className="flex items-center gap-4">
                    <div className="flex items-center h-16 bg-gray-200 dark:bg-gray-700 rounded-lg px-2 print:bg-gray-200">
                      <div className="w-24 h-8 bg-[#E6CCB3] relative flex items-center print:bg-[#E6CCB3]">
                        {resistor.bands.map((digit, idx) => (
                          <div
                            key={idx}
                            className={`absolute w-3 h-full ${colorCodes[digit].bg}`}
                            style={{ left: `${idx * 15 + 10}%` }}
                          />
                        ))}
                        <div
                          className={`absolute w-3 h-full ${
                            colorCodes[resistor.multiplierBand].bg
                          }`}
                          style={{
                            left: `${resistor.bands.length * 15 + 10}%`,
                          }}
                        />
                        {resistor.numBands >= 4 && (
                          <div
                            className="absolute w-3 h-full bg-yellow-600"
                            style={{
                              left: `${resistor.bands.length * 15 + 25}%`,
                            }}
                          />
                        )}
                        {resistor.numBands === 6 && (
                          <div
                            className="absolute w-3 h-full bg-amber-900"
                            style={{
                              left: `${resistor.bands.length * 15 + 40}%`,
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-between pr-8">
                        <p className="font-medium text-gray-900 dark:text-stone-100 print:text-black">
                          Value:{" "}
                          {resistor.value >= 1000000
                            ? `${resistor.value / 1000000}M`
                            : resistor.value >= 1000
                            ? `${resistor.value / 1000}K`
                            : resistor.value}
                          Ω ({resistor.numBands}-band)
                        </p>
                        <div className="flex-shrink-0">
                          <ResistorDots quantity={resistor.quantity} />
                        </div>
                      </div>
                      <p className="text-gray-900 dark:text-stone-100 print:text-black">
                        Quantity: {resistor.quantity}
                        {resistor.quantity > 6 && (
                          <span className="text-red-600 dark:text-red-400 print:text-red-600 ml-2">
                            (Showing 6 of {resistor.quantity} pairs)
                          </span>
                        )}
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-300 print:text-gray-600">
                        Colors:{" "}
                        {resistor.bands
                          .map((digit) => colorCodes[digit].color)
                          .join(", ")}
                        , {colorCodes[resistor.multiplierBand].color}{" "}
                        (multiplier)
                        {resistor.numBands >= 4 && ", gold (tolerance)"}
                        {resistor.numBands === 6 && ", brown (temp)"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="w-full py-3 px-4 bg-gray-200 dark:bg-gray-800 print:hidden">
        {" "}
        {/* Reduced padding */}
        <div className="max-w-2xl mx-auto text-center">
          <p className="text-gray-600 dark:text-gray-300">
            This is a print-friendly resistor organizer for your project.
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {" "}
            {/* Reduced text size */}
            by Amos Weislib
          </p>
          <div className="mt-2 flex items-center justify-center space-x-8">
            {" "}
            {/* Reduced margin */}
            <a
              href="https://www.linkedin.com/in/amos-weislib-047b8915a/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 dark:text-blue-400 hover:underline inline-flex items-center"
            >
              <svg
                className="h-4 w-4 mr-1" // Reduced icon size and margin
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
              </svg>
              Connect on LinkedIn
            </a>
            <a
              href="https://www.buymeacoffee.com/amosweislib"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm inline-flex items-center px-3 py-1 bg-[#FFDD00] text-black rounded-lg hover:bg-[#FFE538] transition-colors"
            >
              <img
                src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
                alt="Buy me a coffee"
                className="h-4 mr-1" // Reduced size and margin
              />
              Buy me a coffee
            </a>
          </div>
          <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
            {" "}
            {/* Reduced margin and text size */}© {new Date().getFullYear()}{" "}
            Resistor Organizer. All rights reserved.
          </p>
        </div>
      </footer>
      <StructuredData />
    </div>
  );
}

export default App;
