import React from 'react';

function StructuredData() {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebApplication",
    "name": "Resistor Organizer",
    "description": "Free online tool to calculate resistor color codes and organize electronic components",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Any",
    "author": {
      "@type": "Person",
      "name": "Amos Weislib",
      "url": "https://www.linkedin.com/in/amos-weislib-047b8915a/"
    },
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "about": {
      "@type": "Thing",
      "name": "Electronic Components",
      "description": "Tool for organizing and identifying resistors through their color codes"
    },
    "features": [
      "Resistor color code calculator",
      "Printable mounting points",
      "Multiple band support (3-6 bands)",
      "Dark mode support",
      "Quantity tracking"
    ]
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
    />
  );
}

export default StructuredData;