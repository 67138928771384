export const colorCodes = {
  "-2": { color: "silver", bg: "bg-gray-300", text: "text-black" },
  "-1": { color: "gold", bg: "bg-yellow-600", text: "text-white" },
  0: { color: "black", bg: "bg-black", text: "text-white" },
  1: { color: "brown", bg: "bg-amber-900", text: "text-white" },
  2: { color: "red", bg: "bg-red-600", text: "text-white" },
  3: { color: "orange", bg: "bg-orange-500", text: "text-white" },
  4: { color: "yellow", bg: "bg-yellow-400", text: "text-black" },
  5: { color: "green", bg: "bg-green-600", text: "text-white" },
  6: { color: "blue", bg: "bg-blue-600", text: "text-white" },
  7: { color: "violet", bg: "bg-purple-600", text: "text-white" },
  8: { color: "gray", bg: "bg-gray-500", text: "text-white" },
  9: { color: "white", bg: "bg-white border border-gray-300", text: "text-black" }
};

export const multipliers = {
  'none': { value: 1 },
  'K': { value: 1000 },
  'M': { value: 1000000 }
};