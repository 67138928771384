import React from 'react';

const ResistorDots = ({ quantity }) => {
  const maxPairs = 6;
  const exceededLimit = quantity > maxPairs;
  const pairsToShow = Math.min(quantity, maxPairs);
  
  // Create array of pairs for top and bottom rows
  const topRow = Array.from({ length: Math.ceil(pairsToShow/2) }, (_, i) => i);
  const bottomRow = Array.from({ length: Math.floor(pairsToShow/2) }, (_, i) => i + Math.ceil(pairsToShow/2));
  
  return (
    <div className="flex flex-col gap-[0.3cm]">
      {/* Top row */}
      <div className="flex justify-end gap-[1.8cm]"> {/* Updated gap to 1.8cm */}
        {topRow.map((pair) => (
          <div key={`top-${pair}`} className="flex gap-[1.2cm]"> {/* Updated gap to 1.2cm */}
            <div className="w-2 h-2 bg-black rounded-full print:!bg-black" />
            <div className="w-2 h-2 bg-black rounded-full print:!bg-black" />
          </div>
        ))}
      </div>
      {/* Bottom row */}
      <div className="flex justify-end gap-[1.8cm]"> {/* Updated gap to 1.8cm */}
        {bottomRow.map((pair) => (
          <div key={`bottom-${pair}`} className="flex gap-[1.2cm]"> {/* Updated gap to 1.2cm */}
            <div className="w-2 h-2 bg-black rounded-full print:!bg-black" />
            <div className="w-2 h-2 bg-black rounded-full print:!bg-black" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResistorDots;